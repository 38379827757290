
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import { MyListing } from '@/types/publishing.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import ListingTableRow from '@/components/Publish/ListingTableRow.vue';

@Component({
  components: {
    GButton,
    ListingTableRow,
  },
})
export default class ListingEntry extends Vue {
  @Prop({ type: Object, required: true }) readonly listing!: MyListing;
  @Prop(Boolean) readonly isAdminMode!: boolean;
  private open = false;

  @Emit('add')
  add(listing: MyListing) {}
}
