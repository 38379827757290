
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import { RawLocation } from 'vue-router';
import { MyListing, MyListingVersion } from '@/types/publishing.types';
import { RouteNames, Statuses } from '@/constants';
import { PublishingModule } from '@/store/modules/publishing.module';
import GButton from '@/components/gsk-components/GskButton.vue';
import UserList from '@/components/UserList.vue';
import { UserModule } from '@/store/modules/user.module';
import { User } from '@/types/users.types';
import { EnumsModule } from '@/store/modules/enums.module';

interface StatusInfoSummaryData {
  label: string;
  description: string;
  count: number;
}

@Component({
  components: {
    GButton,
    UserList,
  },
})
export default class ListingTableRow extends Vue {
  @Prop({ type: Object, required: true }) readonly listing!: MyListing;
  @Prop({ type: Object, required: true }) readonly listingVersion!: MyListingVersion;
  @Prop({ type: Boolean, required: true }) readonly first!: boolean;
  @Prop({ type: Boolean, required: true }) readonly open!: boolean;
  @Prop({ type: Boolean }) readonly isVersion!: boolean;
  @Prop(Boolean) readonly isAdminMode!: boolean;

  get lv() {
    return this.listingVersion;
  }

  nameClick() {
    if (this.isPublished) {
      this.$router.push(this.listingVersionLink);
    }
  }

  get listingStatus() {
    return this.getRowStatus(this.listing);
  }

  get checklistingStatus() {
    return [Statuses.PendingApproval, Statuses.Rejected, Statuses.Draft].includes(
      this.listing.statusName,
    );
  }

  get statsuInfoSummary(): StatusInfoSummaryData[] {
    const results: StatusInfoSummaryData[] = [];

    if (this.open) {
      const isVersion = this.listing.versions.length > 0;
      const ref: any = isVersion ? this.listingVersion : this.listing;
      results.push({ count: 1, ...this.getRowStatus(ref) });
    } else if (this.listing.versions.length > 0) {
      this.listing.versions.forEach(current => {
        const tempRowStatus = this.getRowStatus(current);
        const previousSameState = results.find(a => a.label === tempRowStatus.label);

        if (previousSameState) {
          previousSameState.count++;
        } else {
          results.push({ count: 1, ...tempRowStatus });
        }
      });
    }

    return results;
  }

  getRowStatus(ref: any): { label: string; description: string } {
    const status = ref.statusMnemonic;
    const workflowStatus = ref.workflowStatusMnemonic;
    const lastPublished = ref.publishDate;
    let rowStatus = { label: '', description: '' };

    // Pending Approval
    if (workflowStatus === 'PENDINGAPPROVAL') {
      rowStatus = {
        label: 'Pending Approval',
        description:
          'A listing that is under review by an admin. Applies to new and published listings.',
      };
    }
    // Rejected
    else if (workflowStatus === 'REJECTED') {
      rowStatus = {
        label: 'Changes Requested',
        description: 'The reviewer has requested the following changes: ' + ref.rejectionMessage,
      };
    }
    // Draft
    else if (status === 'DRAFT' && !lastPublished) {
      rowStatus = {
        label: 'Draft',
        description: 'Listing that is not ready for review and unpublished.',
      };
    }
    // Unpublished Changes
    else if (status === 'DRAFT' && !!lastPublished) {
      rowStatus = {
        label: 'Unpublished Changes',
        description:
          "Unpublished changes are edits that you've made to a published listing, without republishing them.",
      };
    }

    return rowStatus;
  }

  get hasMultipleVersions(): boolean {
    return this.listing.versions.length > 1;
  }

  longDate(ds: string) {
    const date = new Date(ds);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  get modDate() {
    return this.longDate(this.listingVersion.updateTimestamp);
  }

  get currentUser(): User {
    return UserModule.user;
  }

  get isPrivate(): boolean {
    this.$logger.log(PublishingModule.draftListing.isPrivate);
    return PublishingModule.draftListing.isPrivate ? true : false;
  }

  get userIsOwner(): boolean {
    return true;
  }

  @Emit('update:open')
  toggle() {
    return !this.open;
  }

  showMenu() {
    // refs are an array because they are used in v-for
    const menu = this.$refs.menu as Menu;
    const anchor = this.$refs.menuAnchor as Element;
    menu.setAnchorCorner(menu.Corner.BOTTOM_RIGHT);
    menu.setAnchorElement(anchor);
    menu.setAnchorMargin({ bottom: 16, right: this.getDistance });
    menu.open = !menu.open;
  }

  @Emit('admin')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openDialog(listing: MyListing, listingVersion: MyListingVersion) {}

  @Emit('delete')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  del(listing: MyListing, listingVersion: MyListingVersion, isVersion: boolean) {}

  @Emit('unpublish')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  unpublish(listing: MyListing, listingVersion: MyListingVersion, isVersion: boolean): void {}

  get listingVersionLink(): RawLocation {
    const listingVersionLocation = {
      name: RouteNames.ListingDetails,
      params: {
        listingId: this.listing.listingId.toString(),
      },
    };

    if (this.listingVersion.listingVersionId) {
      return {
        ...listingVersionLocation,
        query: { version: this.listingVersion.listingVersionId.toString() },
      };
    } else {
      return listingVersionLocation;
    }
  }

  get editLink() {
    const listingVersionId = this.listingVersion.listingVersionId;
    return {
      name: RouteNames.PublishListing,
      params: {
        listingId: this.listing.listingId.toString(),
        listingVersionId: listingVersionId ? listingVersionId.toString() : undefined,
      },
      query: { mode: 'edit' },
    };
  }

  get isPublished() {
    return this.listingVersion.statusName === Statuses.Published;
  }

  get versions() {
    return this.listingVersion.versionId;
  }

  get getDistance() {
    return this.isPublished && !this.isAdminMode ? -35 : -95;
  }

  get listingType(): string | undefined {
    return EnumsModule.enumsById.listingType[this.listing.listingTypeId].name;
  }

  get shouldRowStatusGoToNewLine() {
    return this.hasMultipleVersions && !this.open;
  }
}
